import { state } from './slider_state';
import { moveView } from './moveView';
import { arrowsValidation } from './arrowsValidation';

export function miniMap_init () {
  let gridMap = document.querySelector('#gridMap');
  let gridArray = state.theGrid;
  let theGridColumns = gridArray[0].length;
  let theGridRows = gridArray.length;

  //////////////////////////////////////////////////////////////////////////////
  //  Creates the mini map of the grid
  let counter = 0;
  let itemWidth = 110;
  let itemHeight = 75;

  let gridItemContainer = document.createElement('div');
  gridItemContainer.id = 'miniGrid-wrapper';
  gridItemContainer.style.height = theGridRows * itemHeight + 'px';
  gridItemContainer.style.width = theGridColumns * itemWidth + 'px';
  gridMap.style.height = theGridRows * itemHeight + 'px';
  gridMap.style.width = theGridColumns * itemWidth + 'px';

  for (let i = 0; i < theGridRows; i++) {
    for (let j = 0; j < theGridColumns; j++) {
      let name = gridArray[i][j];
      if (name !== '.') {
        counter++;
        let gridItem = document.createElement('div');
        gridItem.id = 'miniGrid__' + name;
        gridItem.className = 'miniGrid__item';
        gridItem.style.transform = 'translate(' + (j * itemWidth) + 'px, ' + (i * itemHeight) + 'px)';
        gridItem.setAttribute('data-row', i + '');
        gridItem.setAttribute('data-col', j + '');
        gridItem.onpointerdown = () => {
          let row = gridItem.getAttribute('data-row');
          let col = gridItem.getAttribute('data-col');
          moveView('center', null, { row: row, col: col });
        };

        let innerBg = document.createElement('div');
        innerBg.className = 'miniGrid__item-background';

        let inner = document.createElement('div');
        inner.className = 'miniGrid__item-inner';

        gridItem.append(innerBg, inner);
        gridItemContainer.append(gridItem);
      }
    }
  }

  gridMap.append(gridItemContainer);

  //////////////////////////////////////////////////////////////////////////////
  arrowsValidation();
  moveView('center');
}