<template>
<div id="Slider_container">

  <div id="Slider__backgrounds">
    <div id="background_one"></div>
    <div id="background_two"></div>
  </div>

  <div id="arrow_up_left" class="an_arrow" @click="moveView('up_left', $event)">
    <i></i><i></i>
  </div>

  <div id="arrow_up" class="an_arrow" @click="moveView('up', $event)">
    <i></i><i></i>
  </div>

  <div id="arrow_up_right" class="an_arrow" @click="moveView('up_right', $event)">
    <i></i><i></i>
  </div>

  <div id="arrow_left" class="an_arrow" @click="moveView('left', $event)">
    <i></i><i></i>
  </div>

  <div id="arrow_right" class="an_arrow" @click="moveView('right', $event)">
    <i></i><i></i>
  </div>

  <div id="arrow_down_left" class="an_arrow" @click="moveView('down_left', $event)">
    <i></i><i></i>
  </div>

  <div id="arrow_down" class="an_arrow" @click="moveView('down', $event)">
    <i></i><i></i>
  </div>

  <div id="arrow_down_right" class="an_arrow" @click="moveView('down_right', $event)">
    <i></i><i></i>
  </div>

  <div id="grid_map_link" @click="miniMap_toggle">Map</div>
  <!--  <div id="grid_CV_link" @click="showCV">CV</div>  -->

  <div id="the_center" class="center-animation-scale"></div>

  <div id="gridMap_container">
    <div id="gridMap_inner">
      <div id="gridMap_close" @click="miniMap_toggle">&times;</div>
      <div id="gridMap">
        <div class="gridMap__easy-mode" @click="miniMap_shuffle('easy')">Easy mode</div>
        <div class="gridMap__shuffle" @click="miniMap_shuffle">Shuffle</div>
      </div>
    </div>
  </div>

</div>
</template>

<script>
import { onMounted } from 'vue';
import { moveView } from '../js/slider/moveView';
import { miniMap_init } from '../js/slider/miniMap_init';
import { miniMap_shuffle } from '../js/slider/miniMap_shuffle';
import { miniMap_toggle } from '../js/slider/miniMap_toggle';


export default {
  name: 'Slider',
  setup () {

    onMounted(() => {
      let bg_one = document.getElementById('background_one');
      let bg_two = document.getElementById('background_two');
      bg_one.style.zIndex = '20';
      bg_two.style.zIndex = '10';

      miniMap_init();

      document.addEventListener('keydown', event => {
        if (event.key === 'Escape') miniMap_toggle();
      });
    });

    function showCV () {
      let gridItem = document.getElementById('miniGrid__I');
      let row = gridItem.getAttribute('data-row');
      let col = gridItem.getAttribute('data-col');
      moveView('center', null, { row, col });
    }

    return {
      moveView,
      miniMap_shuffle,
      miniMap_toggle,
      showCV,
    };
  },
};
</script>

<style lang="stylus">
$animation-timing = cubic-bezier(.55, 0, .1, 1);
$animation-duration = 1s;
.aBox
  position absolute
  height 100%
  width 100%
  box-sizing border-box
  transition transform $animation-duration $animation-timing
  will-change transform

//
//  Animations that allow the overlay that contains the mini map to appear / disappear
//
.animation__fadeIn
  animation fadeIn 1s
  animation-fill-mode forwards

.animation__fadeOut
  animation fadeOut 1s
  animation-fill-mode forwards

@keyframes fadeIn
  0%
    transform translateY(0vh)
    opacity: 0;
  0.1%
    transform translateY(0vh)
    opacity: 0;
  100%
    transform translateY(0vh)
    display: block;
    opacity: 1;

@keyframes fadeOut
  0%
    transform translateY(0vh)
    opacity: 1;
  99.9%
    transform translateY(0vh)
    opacity: 0;
  100%
    transform translateY(200vh) // must be 200 because #gridMap has scale(1.5), it will overflow a bit in the bottom
    opacity: 0;

#miniGrid-wrapper
  position relative
  .miniGrid__item
    transition transform 1500ms
    position absolute
    z-index 100
    width 110px
    height 75px
    display flex
    align-items center
    justify-content center
    &[data-is-visible]
      .miniGrid__item-inner
        opacity 1
        filter blur(0)
    &[data-is-visible="is_visible"]
      .miniGrid__item-inner
        box-shadow 0 0 0 2px lightgreen
        transform scale(1.1)
    &:hover
      z-index 200
      .miniGrid__item-inner
        transform scale(1.1)
      .miniGrid__item-background
        transform scale(1.1)
    &#miniGrid__A
      .miniGrid__item-inner
        background-image url(../../public/thumbnails/firstSlide.jpg);
        background-position bottom left
    &#miniGrid__B
      .miniGrid__item-inner
        background-image url(../../public/thumbnails/goldenRatio.jpg);
    &#miniGrid__C
      .miniGrid__item-inner
        background-image url(../../public/thumbnails/clock.jpg);
    &#miniGrid__D
      .miniGrid__item-inner
        background-image url(../../public/thumbnails/squares.jpg);
    &#miniGrid__E
      .miniGrid__item-inner
        background-image url(../../public/thumbnails/menus.jpg);
    &#miniGrid__F
      .miniGrid__item-inner
        background-image url(../../public/thumbnails/magic.jpg);
    &#miniGrid__G
      .miniGrid__item-inner
        background-image url(../../public/thumbnails/waves.jpg);
    &#miniGrid__H
      .miniGrid__item-inner
        background-image url(../../public/thumbnails/scramble.jpg);
    &#miniGrid__I
      .miniGrid__item-inner
        background-image url(../../public/thumbnails/skills.jpg);
        background-position bottom left
    &#miniGrid__J
      .miniGrid__item-inner
        background-image url(../../public/thumbnails/spiral.jpg);
    &#miniGrid__K
      .miniGrid__item-inner
        background-image url(../../public/thumbnails/movies.png);
    &#miniGrid__L
      .miniGrid__item-inner
        background-image url(../../public/thumbnails/chatModal.jpg);
    &#miniGrid__M
      .miniGrid__item-inner
        background-image url(../../public/thumbnails/clipPath.jpg);
    &#miniGrid__N
      .miniGrid__item-inner
        background-image url(../../public/thumbnails/incline.jpg);
    &#miniGrid__O
      .miniGrid__item-inner
        background-image url(../../public/thumbnails/background.jpg);
    &#miniGrid__P
      .miniGrid__item-inner
        background-image url(../../public/thumbnails/photo.jpg);
    &#miniGrid__Q
      .miniGrid__item-inner
        background-image url(../../public/thumbnails/pictures.jpg);
    &#miniGrid__R
      .miniGrid__item-inner
        background-image url(../../public/thumbnails/card.jpg);
    &#miniGrid__S
      .miniGrid__item-inner
        background-image url(../../public/thumbnails/tabs.jpg);
    &#miniGrid__T
      .miniGrid__item-inner
        background-image url(../../public/thumbnails/mirrorSphere.jpg);
    &#miniGrid__U
      .miniGrid__item-inner
        background-image url(../../public/thumbnails/theEnd.png);
    .miniGrid__item-inner
      transition opacity 500ms, transform 500ms
      background-repeat no-repeat
      background-position center
      background-size cover
      box-sizing border-box
      border-radius 7px
      position relative
      z-index 150
      width 98px
      height 61px
      opacity 0.2
      cursor pointer
    .miniGrid__item-background
      transition opacity 500ms, transform 500ms
      position absolute
      background-color black
      border-radius 7px
      z-index 100
      width 98px
      height 61px

#gridMap_random
  position absolute
  background rgba(250, 0, 0, 0.2)
  width 100px
  height 70px
  display flex
  align-items center
  justify-content center
  font-size 23px
  font-weight bolder
  color white

.gridMap_random2
  position absolute
  background rgba(0, 0, 250, 0.2)
  width 100px
  height 70px
  display flex
  align-items center
  justify-content center
  font-size 23px
  font-weight bolder
  color white

//
//  Backgrounds for the Slider
//
.background__A
  background-color #3B2F63
  background-image: linear-gradient(-33deg, #ff7f40, #4d9f0c);

.background__B
  background-color #75a9e8
  background-image: radial-gradient(at 50% top, rgba(84, 90, 182, 0.6) 0%, transparent 75%),
    radial-gradient(at right top, #794aa2 0%, transparent 57%),
    radial-gradient(at left bottom, #0a5682 0%, transparent 57%);

.background__C
  background-color #5c499c
  background-image: radial-gradient(at 0% top, #5474bf 0%, transparent 50%),
    radial-gradient(at right top, #924cd0 0%, transparent 57%),
    radial-gradient(at right bottom, #5a186f 0%, transparent 50%);

.background__D
  background-color #F8C390
  background-image: radial-gradient(ellipse at top, #e66465, transparent),
    radial-gradient(ellipse at bottom, #4d9f0c, transparent);

.background__E
  background-image: linear-gradient(35deg, #7085B6 0%, #87A7D9 50%, #DEF3F8 100%);

.background__F
  background-image: radial-gradient(at center, #2880bb, #682882);

.background__G
  background-image: linear-gradient(45deg, #F2BDDD, #5D99CE 45%, #5D99CE 55%, #9DD5C7);

.background__H
  background-image: linear-gradient(-225deg, #3D4E81 0%, #5753C9 50%, #6E7FF3 100%);

.background__I
  background-image: linear-gradient(-225deg, #b405ff 0%, #7c5cff 50%, #4CC3FF 100%);

.background__J
  background-image: linear-gradient(60deg, #3d3393 0%, #2b76b9 35%, #2cacd1 65%, #35eb93 100%);

.background__K
  background-image: linear-gradient(to top, #096654 0%, #6aa060 100%);

.background__L
  background-image linear-gradient(to bottom left, #5a310f 10%, #5e0e22, #610a2d, #3c094c)

.background__M
  background-image: linear-gradient(120deg, #a16fd2 0%, #3670aa 100%);

.background__N
  background-image: linear-gradient(-30deg, #D897B5 0%, #5F538D 100%);

.background__O
  background-image: linear-gradient(to top, #d299c2 0%, #fef9d7 100%);

.background__P
  background-image: linear-gradient(135deg, #667eea 0%, #764ba2 100%);

.background__Q
  background-image: linear-gradient(to right, #e4afcb 0%, #b8cbb8 0%, #b8cbb8 0%, #e2c58b 30%, #c2ce9c 64%, #7edbdc 100%);

.background__R
  background-image: linear-gradient(to top, #6a85b6 0%, #bac8e0 100%);

.background__S
  background-image: linear-gradient(-60deg, #16a085 0%, #f4d03f 100%);

.background__T
  background-image: linear-gradient(to top, #3b41c5 0%, #a981bb 49%, #ffc8a9 100%);

.background__U
  background-image: linear-gradient(35deg, #71543a 0%, #998746 50%, #b6c28c 100%);
</style>

<style lang="stylus" scoped>
#Slider_container
  position absolute
  overflow hidden
  top 0
  right 0
  bottom 0
  left 0
  background white
  #Slider__backgrounds
    position absolute
    z-index 5
    top 0
    right 0
    bottom 0
    left 0
    #background_one, #background_two
      position absolute
      top 0
      right 0
      bottom 0
      left 0
      transition opacity 1s
      background-repeat no-repeat
      background-size 100% 100%
  #arrow_up_left
    top 25px
    left 25px
    transform rotate(45deg)
  #arrow_up
    top 15px
    left calc(50% - 20px)
    transform rotate(90deg)
  #arrow_up_right
    top 25px
    right 25px
    transform rotate(135deg)
  #arrow_down
    bottom 15px
    left calc(50% - 20px)
    transform rotate(270deg)
  #arrow_left
    left 20px
    top calc(50% - 15px)
    transform rotate(0deg)
  #arrow_right
    right 20px
    top calc(50% - 15px)
    transform rotate(180deg)
  #arrow_down_left
    bottom 25px
    left 25px
    transform rotate(315deg)
  #arrow_down_right
    bottom 25px
    right 25px
    transform rotate(225deg)
  #grid_map_link, #grid_CV_link
    text-transform uppercase
    letter-spacing 1px
    font 500 12px Averta, sans-serif;
    cursor pointer
    position absolute
    z-index 1000
    top 0
    background rgba(0, 0, 0, 0.5)
    border-radius 0 0 10px 10px
    padding 2px 8px 4px 10px
    color white
    display flex
    align-items center
    justify-content center
  #grid_map_link
    left 10%
  #grid_CV_link
    left calc(10% + 80px)
  .an_arrow
    transition transform 1s, background 1s, opacity 1.5s
    position absolute
    z-index 1000
    filter drop-shadow(0 -2px 0px rgba(0, 0, 0, 0.5))
    width 30px
    height 40px
    i
      transform-origin 0 50%
      position absolute
      top calc(50% - 2px)
      left 0
      width 20px
      height 4px
      border-radius 3px
      background white
      transition all 0.3s ease
    i:first-child
      transform translate(0, 1px) rotate(-40deg)
    i:last-child
      transform translate(0, -1px) rotate(40deg)
  .an_arrow:hover
    i:first-child
      transform translate(0, 0.5px) rotate(-20deg)
    i:last-child
      transform translate(0, -0.5px) rotate(20deg)
  .an_arrow:active
    i:first-child
      transform translate(1px, 0px) rotate(-10deg)
    i:last-child
      transform translate(1px, 0px) rotate(10deg)
  .an_arrow[data-arrow-validation="not_valid"]
    opacity 0.1
    cursor default
    i:first-child
      transform translate(-10px, 0) rotate(0deg)
    i:last-child
      transform translate(-10px, 0) rotate(0deg)
  .an_arrow[data-arrow-validation="not_valid"]:hover
    i:first-child
      transform translate(-10px, 0) rotate(0deg)
    i:last-child
      transform translate(-10px, 0) rotate(0deg)
  .an_arrow[data-arrow-validation="is_valid"]
    cursor pointer
  #the_center
    position absolute
    z-index 200
    top 0
    right 0
    bottom 0
    left 0
    transition transform 1s
    overflow hidden
  #gridMap_container
    position absolute
    z-index 200
    top 0
    right 0
    bottom 0
    left 0
    transform translateY(200vh)
    transition opacity 1s
    opacity 0
    #gridMap_inner
      background rgba(0, 0, 50, 0.5)
      position absolute
      top 0
      right 0
      bottom 0
      left 0
      display flex
      align-items center
      justify-content center
      #gridMap_close
        color #d4d4d4
        position absolute
        top 20px
        right 45px
        font 300 60px Averta, sans-serif;
        cursor pointer
        transition 300ms
      #gridMap
        background rgba(0, 0, 0, 0.7)
        transition transform 1s
        transform scale(1.5)
        box-sizing content-box
        border-radius 15px
        position relative
        padding 15px
        .gridMap__shuffle, .gridMap__easy-mode
          position absolute
          background rgba(250, 250, 250, 0.1)
          border-radius 10px
          padding 2px 15px
          font 400 14px Averta, sans-serif;
          cursor pointer
          color white
        .gridMap__shuffle
          top -35px
          left 130px
        .gridMap__easy-mode
          top -35px
          left 250px
</style>
