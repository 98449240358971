import { reactive } from 'vue';

export let state = reactive({
  current_row: 6,
  current_col: 0,
  visible_view: '',
  theGrid: [
    ['.', 'Q', 'P', 'O', 'N', 'M', '.', '.', '.'],
    ['R', '.', '.', '.', '.', '.', 'L', '.', '.'],
    ['.', 'S', 'T', 'U', '.', '.', '.', 'K', '.'],
    ['.', '.', '.', '.', '.', '.', '.', '.', 'J'],
    ['.', '.', 'C', 'D', 'E', '.', '.', '.', 'I'],
    ['.', 'B', '.', '.', '.', 'F', '.', 'H', '.'],
    ['A', '.', '.', '.', '.', '.', 'G', '.', '.']
  ]
});