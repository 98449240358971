export async function importView (viewName) {
  let theView;

  if (viewName === 'A') theView = await import('../../views/FirstSlide');
  if (viewName === 'B') theView = await import('../../views/GoldenRatio');
  if (viewName === 'C') theView = await import('../../views/EinsteinClock');
  if (viewName === 'D') theView = await import('../../views/Squares');
  if (viewName === 'E') theView = await import('../../views/InteractiveMenus');
  if (viewName === 'F') theView = await import('../../views/Magic');
  if (viewName === 'G') theView = await import('../../views/Waves');
  if (viewName === 'H') theView = await import('../../views/Scramble');
  if (viewName === 'I') theView = await import('../../views/Skills');
  if (viewName === 'J') theView = await import('../../views/Spiral');
  if (viewName === 'K') theView = await import('../../views/Carousel');
  if (viewName === 'L') theView = await import('../../views/ChatModal');
  if (viewName === 'M') theView = await import('../../views/ClipPath');
  if (viewName === 'N') theView = await import('../../views/Incline');
  if (viewName === 'O') theView = await import('../../views/MoveBackground');
  if (viewName === 'P') theView = await import('../../views/Photo');
  if (viewName === 'Q') theView = await import('../../views/Pictures');
  if (viewName === 'R') theView = await import('../../views/ProfileCard');
  if (viewName === 'S') theView = await import('../../views/SlidingTabs');
  if (viewName === 'T') theView = await import('../../views/Reflection');
  if (viewName === 'U') theView = await import('../../views/TheEnd');

  return theView;
}