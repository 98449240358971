let toggleTest = false;


export function miniMap_toggle () {
  let Slider_container = document.getElementById('Slider_container');
  let _container = Slider_container.getBoundingClientRect();

  let gridMap_container = document.getElementById('gridMap_container');
  let the_center = document.getElementById('the_center');
  let gridMap = document.getElementById('gridMap');

  let marginX = (_container.width - 1020) / 2;
  let marginY = (_container.height - 555) / 2;
  let extra = 50;

  let up_left = document.getElementById('arrow_up_left');
  let up = document.getElementById('arrow_up');
  let up_right = document.getElementById('arrow_up_right');
  let left = document.getElementById('arrow_left');
  let right = document.getElementById('arrow_right');
  let down_left = document.getElementById('arrow_down_left');
  let down = document.getElementById('arrow_down');
  let down_right = document.getElementById('arrow_down_right');

  if (toggleTest) {
    gridMap_container.classList.add('animation__fadeOut');
    gridMap_container.classList.remove('animation__fadeIn');

    the_center.style.transform = 'scale(1)';

    up_left.style.transform = '';
    up.style.transform = '';
    up_right.style.transform = '';

    left.style.transform = '';
    right.style.transform = '';

    down_left.style.transform = '';
    down.style.transform = '';
    down_right.style.transform = '';
    gridMap.style.transform = '';
  } else {
    gridMap_container.classList.add('animation__fadeIn');
    gridMap_container.classList.remove('animation__fadeOut');


    the_center.style.transform = 'scale(0.5)';

    up_left.style.transform = `translate(${marginX - extra}px, ${marginY - extra}px) rotate(45deg)`;
    up.style.transform = `translate(0px, ${marginY - extra}px) rotate(90deg)`;
    up_right.style.transform = `translate(${-(marginX) + extra}px, ${marginY - extra}px) rotate(135deg)`;

    left.style.transform = `translate(${marginX - extra}px, 0px) rotate(0deg)`;
    right.style.transform = `translate(${-(marginX) + extra}px, 0px) rotate(180deg)`;

    down_left.style.transform = `translate(${marginX - extra}px, ${-(marginY) + extra}px) rotate(315deg)`;
    down.style.transform = `translate(0px, ${-(marginY) + extra}px) rotate(270deg)`;
    down_right.style.transform = `translate(${-(marginX) + extra}px, ${-(marginY) + extra}px) rotate(225deg)`;

    gridMap.style.transform = 'scale(1)';
  }

  toggleTest = !toggleTest;
}