import { createApp } from 'vue';
import { state } from './slider_state';
import { arrowsValidation } from './arrowsValidation';
import { switchBackground } from './changeBackgrounds';
import { importView } from './importView';


const orientation = {
  up_left: {
    x: 1,
    y: 1,
  },
  up: {
    x: 0,
    y: 1,
  },
  up_right: {
    x: -1,
    y: 1,
  },
  left: {
    x: 1,
    y: 0,
  },
  center: {
    x: 0,
    y: 0,
  },
  right: {
    x: -1,
    y: 0,
  },
  down_left: {
    x: 1,
    y: -1,
  },
  down: {
    x: 0,
    y: -1,
  },
  down_right: {
    x: -1,
    y: -1,
  },
};

let current_view = '';
let display_view_next = '';
let next_row = 0;
let next_col = 0;
let is_animating = false;
let mountedComponents = {};


export function moveView (direction, event, directMove) {
  if (event) {
    let target = event.target.closest('.an_arrow');
    let validation = target.getAttribute('data-arrow-validation');
    if (validation === 'is_valid') {
    }
    if (validation === 'not_valid') {
      return;
    }
  }

  if (is_animating) return;
  is_animating = true;

  if (directMove) {
    state.current_row = directMove.row;
    state.current_col = directMove.col;
    Object
      .keys(mountedComponents)
      .forEach(viewID => {
        delete mountedComponents[viewID];
        let element = document.querySelector('#' + viewID);
        element && element.remove();
      });
  }

  function updateMiniMap (row, col) {
    let matrix = state.theGrid;
    let visible = matrix[row][col];

    let last_visible = document.querySelector('[data-is-visible="is_visible"]');
    last_visible && last_visible.setAttribute('data-is-visible', 'not_visible');

    let visibleElement = document.getElementById('miniGrid__' + visible);
    if (visibleElement) {
      visibleElement.setAttribute('data-is-visible', 'is_visible');
    }
  }

  let setCurrentView = view => {
    current_view = view;
    state.current_col = next_col;
    state.current_row = next_row;
  };

  let setView_next = (view, viewName) => {
    display_view_next = view;
    state.visible_view = viewName;
    next_row = state.current_row - orientation[direction].y;
    next_col = state.current_col - orientation[direction].x;
  };

  let matrix = state.theGrid;


  /////////////////////////////////////////////////////////////////////
  //  CREATE
  let create_views = async views => {
    let the_center = document.querySelector('#the_center');

    for (let i = 0; i < views.length; i++) {
      let viewPosition = views[i];

      let new_row = state.current_row - orientation[viewPosition].y;
      let new_col = state.current_col - orientation[viewPosition].x;
      let viewName = matrix[new_row][new_col];

      let the_panel_id = 'aPanel_' + viewName;

      if (viewPosition === direction) setView_next(the_panel_id, viewName);

      if (viewName && (viewName !== '.')) {
        let panel = document.createElement('div');
        panel.className = 'aBox';
        panel.id = the_panel_id;
        panel.style.left = -(orientation[viewPosition].x) + '00vw';
        panel.style.top = -(orientation[viewPosition].y) + '00vh';
        panel.setAttribute('data-translate-x', orientation[viewPosition].x);
        panel.setAttribute('data-translate-y', orientation[viewPosition].y);

        the_center.append(panel);

        switchBackground(viewName);

        let importedView = await importView(viewName);

        if (importedView) {
          let app = createApp(importedView.default);
          app.mount('#' + the_panel_id);
          mountedComponents[the_panel_id] = app;
        }
      }
    }
  };


  /////////////////////////////////////////////////////////////////////
  //  MOVE
  let move_views = () => {
    let the_center = document.querySelector('#the_center');
    let panels = the_center.children;

    for (let i = 0; i < panels.length; i++) {
      let x_attr = panels[i].getAttribute('data-translate-x');
      let y_attr = panels[i].getAttribute('data-translate-y');
      let move_x = orientation[direction].x;
      let move_y = orientation[direction].y;

      if (current_view !== '' && current_view === panels[i].id) {
        let fix_x = parseInt(x_attr) + move_x;
        let fix_y = parseInt(y_attr) + move_y;
        panels[i].style.transform = 'translate(' + fix_x + '00vw, ' + fix_y + '00vh)';
        the_center.classList.add('center-animation-scale');
      } else {
        panels[i].style.transform = 'translate(' + move_x + '00vw, ' + move_y + '00vh)';
      }
    }

    updateMiniMap(next_row, next_col);
    setCurrentView(display_view_next);
    arrowsValidation();
  };


  /////////////////////////////////////////////////////////////////////
  //  REMOVE
  let remove_views = () => {
    let the_center = document.querySelector('#the_center');
    let childNodes = the_center.childNodes;
    let ids = [];

    for (let i = 0; i < childNodes.length; i++) {
      if (childNodes[i].id !== display_view_next) ids.push(childNodes[i].id);
    }

    for (let i = 0; i < ids.length; i++) {
      delete mountedComponents[ids[i]];
      let element = document.querySelector('#' + ids[i]);
      element && element.remove();
    }

    the_center.classList.remove('center-animation-scale');
    is_animating = false;
  };


  if (direction === 'center') create_views(['center']);

  if (direction === 'up') create_views(['up']);

  if (direction === 'down') create_views(['down']);

  if (direction === 'left') create_views(['left']);

  if (direction === 'right') create_views(['right']);

  if (direction === 'up_right') create_views(['up', 'up_right', 'right']);

  if (direction === 'down_right') create_views(['right', 'down_right', 'down']);

  if (direction === 'up_left') create_views(['left', 'up_left', 'up']);

  if (direction === 'down_left') create_views(['down', 'left', 'down_left']);

  setTimeout(move_views, 50);

  setTimeout(remove_views, 1100);
}