import { arrowsValidation } from './arrowsValidation';
import { state } from './slider_state';

let shuffleCounter = null;

export function miniMap_shuffle (mode) {
  if (!shuffleCounter || shuffleCounter === 9) {
    shuffleCounter = 1;
  }

  let matrixCollection = {
    matrix_1: [
      ['.', '.', '.', 'A', '.', '.', '.', '.', '.'],
      ['.', 'C', 'B', '.', '.', '.', '.', '.', '.'],
      ['D', 'F', '.', '.', '.', '.', '.', 'U', '.'],
      ['E', 'G', '.', '.', '.', '.', '.', 'T', 'S'],
      ['.', '.', 'H', 'I', '.', 'M', '.', '.', 'R'],
      ['.', '.', '.', 'J', 'L', 'N', 'P', 'Q', '.'],
      ['.', '.', '.', 'K', '.', 'O', '.', '.', '.'],
    ],
    matrix_2: [
      ['.', '.', 'N', 'M', 'L', '.', '.', '.', '.'],
      ['.', 'O', '.', '.', 'K', 'G', '.', '.', '.'],
      ['.', 'P', '.', 'J', '.', 'H', 'F', '.', '.'],
      ['R', 'Q', '.', '.', 'I', '.', '.', 'E', '.'],
      ['.', 'S', '.', '.', '.', '.', '.', 'D', '.'],
      ['.', '.', 'T', '.', '.', '.', '.', 'C', 'B'],
      ['.', '.', '.', 'U', '.', '.', '.', '.', 'A'],
    ],
    matrix_3: [
      ['A', '.', '.', '.', 'M', 'N', 'O', '.', '.'],
      ['B', '.', '.', '.', 'L', '.', '.', 'P', '.'],
      ['C', 'D', '.', '.', '.', 'K', '.', '.', 'Q'],
      ['.', 'E', 'F', 'G', '.', 'J', '.', '.', 'R'],
      ['.', '.', '.', 'H', 'I', '.', '.', '.', 'S'],
      ['.', '.', '.', '.', '.', '.', '.', 'T', '.'],
      ['.', '.', '.', '.', '.', '.', 'U', '.', '.'],
    ],
    matrix_4: [
      ['.', '.', '.', 'M', 'N', '.', '.', 'Q', '.'],
      ['.', 'K', 'L', '.', '.', 'O', 'P', 'R', '.'],
      ['J', 'I', '.', '.', '.', '.', '.', 'S', 'T'],
      ['.', 'H', 'G', '.', 'D', '.', '.', '.', 'U'],
      ['.', '.', 'F', 'E', 'C', '.', '.', '.', '.'],
      ['.', '.', '.', 'B', '.', '.', '.', '.', '.'],
      ['.', '.', '.', '.', 'A', '.', '.', '.', '.'],
    ],
    matrix_5: [
      ['.', '.', '.', '.', 'I', 'H', '.', 'C', 'B'],
      ['.', '.', '.', 'J', '.', 'G', '.', 'D', 'A'],
      ['U', '.', '.', 'K', '.', '.', 'F', 'E', '.'],
      ['.', 'T', '.', '.', 'L', '.', '.', '.', '.'],
      ['.', '.', 'S', '.', 'M', 'N', '.', '.', '.'],
      ['.', '.', 'R', '.', 'O', '.', '.', '.', '.'],
      ['.', '.', '.', 'Q', 'P', '.', '.', '.', '.'],
    ],

    matrix_6: [
      ['.', '.', '.', 'H', '.', '.', '.', '.', '.'],
      ['.', '.', 'G', '.', 'I', '.', '.', '.', '.'],
      ['E', 'F', '.', '.', 'J', '.', '.', '.', '.'],
      ['D', '.', '.', '.', 'K', 'L', '.', '.', 'U'],
      ['.', 'C', '.', '.', '.', 'M', 'N', '.', 'T'],
      ['.', '.', 'B', '.', '.', 'O', 'P', '.', 'S'],
      ['.', '.', 'A', '.', '.', '.', 'Q', 'R', '.'],
    ],
    matrix_7: [
      ['.', '.', '.', '.', '.', 'K', '.', '.', '.'],
      ['.', 'E', '.', '.', '.', 'J', 'L', '.', '.'],
      ['.', 'D', 'F', '.', 'I', 'N', 'M', '.', '.'],
      ['.', 'C', 'G', 'H', 'P', 'O', '.', '.', '.'],
      ['.', 'B', '.', '.', 'Q', '.', '.', '.', '.'],
      ['A', '.', '.', '.', '.', 'R', 'S', '.', '.'],
      ['.', '.', '.', '.', '.', '.', '.', 'T', 'U'],
    ],
    matrix_8: [
      ['.', 'Q', 'P', 'O', 'N', 'M', '.', '.', '.'],
      ['R', '.', '.', '.', '.', '.', 'L', '.', '.'],
      ['.', 'S', 'T', 'U', '.', '.', '.', 'K', '.'],
      ['.', '.', '.', '.', '.', '.', '.', '.', 'J'],
      ['.', '.', 'C', 'D', 'E', '.', '.', '.', 'I'],
      ['.', 'B', '.', '.', '.', 'F', '.', 'H', '.'],
      ['A', '.', '.', '.', '.', '.', 'G', '.', '.'],
    ],
    matrix_easy: [
      ['.', 'B', 'C', 'D', 'E', 'F', 'G', 'H', '.'],
      ['A', '.', '.', '.', '.', '.', '.', '.', 'I'],
      ['.', '.', '.', '.', '.', '.', '.', '.', 'J'],
      ['.', '.', '.', '.', '.', '.', '.', '.', 'K'],
      ['.', '.', '.', '.', '.', '.', '.', '.', 'L'],
      ['U', '.', '.', '.', '.', '.', '.', '.', 'M'],
      ['.', 'T', 'S', 'R', 'Q', 'P', 'O', 'N', '.'],
    ],
    matrix_hard: [
      ['.', '.', '.', '.', '.', '.', '.', '.', '.'],
      ['.', '.', '.', '.', '.', '.', '.', '.', '.'],
      ['.', '.', '.', '.', '.', '.', '.', '.', '.'],
      ['.', '.', '.', '.', '.', '.', '.', '.', '.'],
      ['.', '.', '.', '.', '.', '.', '.', '.', '.'],
      ['.', '.', '.', '.', '.', '.', '.', '.', '.'],
      ['.', '.', '.', '.', '.', '.', '.', '.', '.'],
    ],
  };

  if (mode === 'easy') shuffleCounter = 'easy';

  let gridArray = matrixCollection['matrix_' + shuffleCounter];
  let theGridColumns = gridArray[0].length;
  let theGridRows = gridArray.length;

  let newRow;
  let newCol;
  let itemWidth = 110;
  let itemHeight = 75;

  for (let i = 0; i < theGridRows; i++) {
    for (let j = 0; j < theGridColumns; j++) {
      let name = gridArray[i][j];

      if (state.visible_view === name) {
        newRow = i;
        newCol = j;
      }

      if (name !== '.') {
        let gridItem = document.getElementById('miniGrid__' + name);
        if (gridItem) {
          gridItem.style.transform = `translate(${j * itemWidth}px, ${i * itemHeight}px)`;
          gridItem.setAttribute('data-row', i + '');
          gridItem.setAttribute('data-col', j + '');
        }
      }
    }
  }

  state.theGrid = gridArray;
  state.current_row = newRow;
  state.current_col = newCol;

  arrowsValidation(newRow, newCol, gridArray);

  shuffleCounter++;
}