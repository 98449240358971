/**
 * These functions are used to fade in - out between the backgrounds
 */


export let switchBackground = viewName => {
  let one = document.getElementById('background_one');
  let two = document.getElementById('background_two');
  let one_zIndex = one.style.zIndex;
  let two_zIndex = two.style.zIndex;

  if (one_zIndex && two_zIndex) {

    let background_back = one_zIndex > two_zIndex ? 'background_two' : 'background_one';
    let background_front = one_zIndex > two_zIndex ? 'background_one' : 'background_two';

    let bg_back = document.getElementById(background_back);
    let bg_front = document.getElementById(background_front);

    bg_back.className = 'background__' + viewName;
    bg_front.style.opacity = '0';

    setTimeout(() => {
      bg_back.style.zIndex = '20';
      bg_front.style.zIndex = '10';
      bg_front.style.opacity = '1';
    }, 1000);

  }
};