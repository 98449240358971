import { state } from './slider_state';

//////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////
//  Validation of the arrows

export function arrowsValidation (rowArg, colArg, matrixArg) {

  let row = rowArg !== null && rowArg !== undefined ? rowArg : state.current_row;
  let col = colArg !== null && colArg !== undefined ? colArg : state.current_col;

  let matrix = matrixArg || state.theGrid;

  let matrixValidation = {
    up_left: {
      name: 'arrow_up_left',
      value: matrix[row - 1] && matrix[row - 1][col - 1],
    },
    up_center: {
      name: 'arrow_up',
      value: matrix[row - 1] && matrix[row - 1][col],
    },
    up_right: {
      name: 'arrow_up_right',
      value: matrix[row - 1] && matrix[row - 1][col + 1],
    },
    left: {
      name: 'arrow_left',
      value: matrix[row] && matrix[row][col - 1],
    },
    right: {
      name: 'arrow_right',
      value: matrix[row] && matrix[row][col + 1],
    },
    down_left: {
      name: 'arrow_down_left',
      value: matrix[row + 1] && matrix[row + 1][col - 1],
    },
    down_center: {
      name: 'arrow_down',
      value: matrix[row + 1] && matrix[row + 1][col],
    },
    down_right: {
      name: 'arrow_down_right',
      value: matrix[row + 1] && matrix[row + 1][col + 1],
    },
  };

  let matrixValues = Object.values(matrixValidation);

  // for (let i = 0; i < matrix.length; i++) {
  //   console.log(i, matrix[i].toString().replaceAll(',', '   '));
  // }

  for (let i = 0; i < matrixValues.length; i++) {
    let name = matrixValues[i].name;
    let value = matrixValues[i].value;
    let arrow = document.getElementById(name);

    if (arrow) {
      value && value !== '.' ?
        arrow.setAttribute('data-arrow-validation', 'is_valid') :
        arrow.setAttribute('data-arrow-validation', 'not_valid');
    }
  }
}